import React from 'react';
import i18next from 'i18next';
import Icon from 'components/Icon';

import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

import QScriptIcon from './assets/qscript_logo.png';
import CommentSmileIcon from './assets/comment_smile.svg';
import RotateExclamation from './assets/rotate-exclamation.svg';
import Megaphone from './assets/megaphone.svg';
import DocotorReport from './assets/doctor-report.svg';
import CommentEdit from './assets/comment-edit.svg';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	// {
	// 	id: 'applications',
	// 	title: 'Applications',
	// 	translate: 'APPLICATIONS',
	// 	type: 'group',
	// 	icon: 'apps',
	// 	children: [

  // 	]
  // }
  {
    id: 'home',
    title: 'Home',
    translate: 'HOME',
    type: 'item',
    icon: <Icon color="white" icon="home" size={1.4} width={1.125} />,
    url: '/home',
  },
  {
    id: 'patient',
    title: 'Patient',
    translate: 'PATIENT',
    type: 'item',
    icon: <Icon color="white" icon="user-circle" size={1.4} width={1.125} />,
    url: '/patient/list',
  },
  {
    id: 'brandSwap',
    title: 'Brand Swap',
    translate: 'BRAND_SWAP',
    type: 'item',
    icon: <Icon color="white" icon="random" size={1.25} width={1.125} />,
    url: '/brand-swap/list',
  },
  {
    id: 'printFoil',
    title: 'Pack Creator',
    translate: 'PRINT_FOIL',
    type: 'item',
    icon: <Icon color="white" icon="copy" size={1.4} width={1.125} />,
    url: '/print-foil',
  },
  {
    id: 'job',
    title: 'Pack Management',
    translate: 'JOB_MANAGEMENT',
    type: 'item',
    icon: <Icon color="white" icon="sort-amount-down-alt" className="transform -rotate-90" size={1.4} width={1.125} />,
    url: '/job/list',
  },
  {
    id: 'vpb',
    title: 'Virtual Pill Balance',
    translate: 'VIRTUAL_PILL_BALANCE',
    type: 'item',
    icon: <Icon color="white" icon="balance-scale" size={1.4} width={1.125} />,
    url: '/vpb/list',
    isInvisible: false,
  },
  {
    id: 'qScript',
    title: 'QScript',
    translate: 'Q_SCRIPT',
    type: 'item',
    icon: <img src={QScriptIcon} alt="Q-Script" height={25} width={25} style={{ maxWidth: 'none' }} />,
    url: '/q-script/list',
    isInvisible: true,
  },
  {
    id: 'dispenseTracker',
    title: 'Dispense Tracker',
    translate: 'DISPENSE_TRACKER',
    type: 'item',
    icon: <Icon color="white" icon="download" size={1.4} width={1.125} />,
    url: '/dispense-tracker/list',
    isInvisible: false,
  },
  {
    id: 'report',
    title: 'Reports',
    translate: 'REPORT',
    type: 'parent',
    icon: <Icon color="white" icon="chart-pie" size={1.4} width={1.125} />,
    children: [
      {
        id: 'patientReport',
        title: 'Patient Reports',
        translate: 'PATIENT_REPORT',
        type: 'item',
        icon: <Icon color="white" icon="address-book" size={1.4} width={1.125} />,
        url: '/report/print',
      },
      {
        id: 'analyticReport',
        title: 'Summary Reports',
        translate: 'SUMMARY_REPORT',
        type: 'item',
        icon: <Icon color="white" icon="chart-bar" size={1.4} width={1.125} />,
        url: '/analytic-report',
      },
      {
        id: 'scriptRequest',
        title: 'Script Request Reports',
        translate: 'SCRIPT_REQUEST_REPORT',
        type: 'item',
        icon: <img src={DocotorReport} alt="Script Request" height={20} width={22.5} style={{ maxWidth: 'none' }} />,
        url: '/script-request',
      },
      {
        id: 'ppaClaimReport',
        title: 'PPA Claim Reports',
        translate: 'PPA_CLAIM_REPORT',
        type: 'item',
        icon: <Icon color="white" icon="users" size={1.4} width={1.125} />,
        url: '/claim',
      },
    ],
  },
  {
    id: 'signing-sheets',
    title: 'Signing Sheets',
    translate: 'SIGNING_SHEETS',
    type: 'item',
    icon: <Icon color="white" icon="address-card" size={1.25} width={1.125} />,
    url: '/signing-sheets',
  },
  {
    id: 'patientGroup',
    title: 'Patient Groups',
    translate: 'PATIENT_GROUP',
    type: 'item',
    icon: <Icon color="white" icon="users" size={1.4} width={1.125} />,
    url: '/patient-group/list',
  },
  {
    id: 'administration',
    title: 'Adminstration',
    translate: 'ADMINISTRATION',
    type: 'parent',
    icon: <Icon color="white" icon="user-cog" size={1.4} width={1.125} />,
    children: [
      {
        id: 'drug',
        title: 'Drugs',
        translate: 'DRUG',
        type: 'item',
        icon: <Icon color="white" icon="pills" size={1.4} width={1.125} />,
        url: '/drug/list',
      },
      {
        id: 'doctor',
        title: 'Doctors',
        translate: 'DOCTOR',
        type: 'item',
        icon: <Icon color="white" icon="user-md" size={1.4} width={1.125} />,
        url: '/doctor/list',
      },
      {
        id: 'direction',
        title: 'Directions',
        translate: 'DIRECTION',
        type: 'item',
        icon: <Icon color="white" icon="file-alt" size={1.4} width={1.125} />,
        url: '/direction/list',
      },
      {
        id: 'user',
        title: 'User',
        translate: 'USER',
        type: 'item',
        icon: <Icon color="white" icon="user-plus" size={1.4} width={1.125} />,
        url: '/user/list',
      },
      {
        id: 'role',
        title: 'Roles',
        translate: 'ROLE',
        type: 'item',
        icon: <Icon color="white" icon="shield-alt" size={1.4} width={1.125} />,
        url: '/role/list',
      },
    ],
  },
];

const navigationBottomConfig = [
  {
    id: 'mypak-help',
    title: 'Help',
    translate: 'MYPAK_HELP',
    type: 'parent',
    icon: <Icon color="white" icon="question-circle" size={1.25} width={1.125} />,
    // url: 'https://mypak.atlassian.net/wiki/spaces/NG/overview',
    children: [
      {
        id: 'helpCentre',
        title: 'Help Centre',
        translate: 'HELP_CENTRE',
        type: 'link',
        icon: <Icon color="white" icon="info-circle" size={1.6} width={1.125} />,
        url: 'https://mypaknghelpcentre.refined.site/space/NG',
      },
      {
        id: 'contactSupport',
        title: 'Contact Support',
        translate: 'CONTACT_SUPPORT',
        type: 'link',
        icon: <img src={CommentSmileIcon} alt="Comment Smile" height={22} width={22} />,
        url: 'https://mypaksolutions.com.au/support/',
      },
      {
        id: 'whatNew',
        title: "What's New",
        translate: 'WHAT_NEW',
        type: 'link',
        icon: <img src={Megaphone} alt="Comment Smile" height={22} width={22} />,
        url: 'https://mypaknghelpcentre.refined.site/space/NG/1223032833/Release+Notes',
      },
      {
        id: 'feedback',
        title: 'Give Feedback',
        translate: 'GIVE_FEEDBACK',
        type: 'item',
        icon: <img src={CommentEdit} alt="Comment Edit" height={23} width={23} />,
        url: '/feedback',
      },
    ],
  },
  {
    id: 'sync',
    title: 'Sync',
    translate: 'SYNC_DATA',
    type: 'item',
    icon: <Icon icon="sync" size={1.4} width={1.125} color="#0af02c" />,
    url: '/sync/notifications',
  },
];

const noSyncDataItem = {
  id: 'noSync',
  title: 'No Sync',
  translate: 'NO_SYNC_DATA',
  type: 'item',
  icon: <img src={RotateExclamation} alt="Rotate-Exclamation" height={20} width={20} style={{ maxWidth: 'none' }} />,
  url: '/sync/notifications',
};

const superAdminNavigationConfig = [
	{
		id: 'pharmacy',
		title: 'Pharmacy',
		translate: 'PHARMACY',
		type: 'item',
		icon: <Icon color="white" icon="building" size={1.25} width={1.125} />,
		url: '/pharmacy/list'
	}
];

const mypakAdminGddConfig = [
  {
    id: 'drug',
    title: 'Drug',
    translate: 'DRUG',
    type: 'item',
    icon: <Icon color="white" icon="pills" size={1.4} width={1.125} />,
    url: '/drug/list',
  },
];

export { navigationConfig, superAdminNavigationConfig, mypakAdminGddConfig, navigationBottomConfig, noSyncDataItem };
