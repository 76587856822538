import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const UPDATE_DRUG_DETAILS_DATA = '@DRUG/UPDATE_DRUG_DETAILS_DATA';
export const UPDATE_DRUG_DETAILS = '@DRUG/UPDATE_DRUG_DETAILS';
export const UPDATE_DRUG_DETAILS_SUCCESS = '@DRUG/UPDATE_DRUG_DETAILS_SUCCESS';
export const UPDATE_DRUG_DETAILS_FAILED = '@DRUG/UPDATE_DRUG_DETAILS_FAILED';

export const UPDATE_DRUG_USAGE_REPORT = '@DRUG/UPDATE_DRUG_USAGE_REPORT';
export const UPDATE_DRUG_USAGE_REPORT_SUCCESS = '@DRUG/UPDATE_DRUG_USAGE_REPORT_SUCCESS';
export const UPDATE_DRUG_USAGE_REPORT_FAILED = '@DRUG/UPDATE_DRUG_USAGE_REPORT_FAILED';

export const UPDATE_CLEAR_FILE_HISTORY = '@DRUG/UPDATE_CLEAR_FILE_HISTORY';

export const UPDATE_DRUG_ID = '@DRUG/UPDATE_DRUG_ID';

export function updateDrugDetailsData(data) {
	return dispatch => {
		dispatch({ type: UPDATE_DRUG_DETAILS_DATA, payload: { data } });
	};
}

export function updateDrugDetails(isMypakAdminUser = false) {
  return (dispatch, getState) => {
    const { details, base } = getState().drug.update;
    const { drugId } = base.data;
    dispatch({ type: UPDATE_DRUG_DETAILS });
    const postData = {
      ...details.data,
      packSize: details.data.packSize || 0,
      shelfLife: details.data.shelfLife || 1,
      isCytotoxic: details.data.isCytotoxic === 'true',
      isDoNotCrush: details.data.isDoNotCrush === 'true',
    };
    HttpService.put(`/api/${!isMypakAdminUser ? 'drugs' : 'master-drugs'}/${drugId}`, postData)
      .then((resp) => {
        dispatch({ type: UPDATE_DRUG_DETAILS_SUCCESS, payload: { data: resp.data } });
        dispatch(
          AppActions.showMessage({
            message: 'Update drug details successfully',
            variant: 'success',
          }),
        );
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: UPDATE_DRUG_DETAILS_FAILED,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export const updateDrugId = newId => {
	return dispatch => {
		dispatch({ type: UPDATE_DRUG_ID, payload: { newId } });
	};
};

export const printUsageReport = (drugId, filterData) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DRUG_USAGE_REPORT });
    return HttpService.post(`/api/drugs/${drugId}/reports/usage`, { ...filterData })
      .then((resp) => {
        dispatch({
          type: UPDATE_DRUG_USAGE_REPORT_SUCCESS,
          payload: { fileName: resp.data.fileName, fileUrl: resp.data.fileUrl },
        });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_DRUG_USAGE_REPORT_FAILED,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};

export const clearFileHistory = () => {
	return dispatch => {
		dispatch({ type: UPDATE_CLEAR_FILE_HISTORY });
	};
};
