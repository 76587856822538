import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_DRUG = '@DRUG/CREATE_DRUG';
export const CREATE_DRUG_SUCCESS = '@DRUG/CREATE_DRUG_SUCCESS';
export const CREATE_DRUG_FAIL = '@DRUG/CREATE_DRUG_FAIL';

export const CHANGE_DRUG_FORM = '@DRUG/CHANGE_DRUG_FORM';

export const CLEAR_CREATE_FORM = '@DRUG/CLEAR_CREATE_FORM';

export function createDrug(isMypakAdminUser = false) {
  return (dispatch, getState) => {
    const { data } = getState().drug.create;
    const postData = {
      ...data,
      packSize: data.packSize || 0,
      shelfLife: data.shelfLife || 1,
      isCytotoxic: data.isCytotoxic === 'true',
      isDoNotCrush: data.isDoNotCrush === 'true',
    };
    dispatch({ type: CREATE_DRUG });
    HttpService.post(`api/${isMypakAdminUser ? 'master-drugs' : 'drugs'}`, postData)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'Drug has been added successfully',
          }),
        );
        dispatch({ type: CREATE_DRUG_SUCCESS, payload: { drugId: resp.data.id } });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_DRUG_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function clearCreateForm() {
	return dispatch => {
		dispatch({ type: CLEAR_CREATE_FORM });
	};
}

export function changeDrugForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_DRUG_FORM, payload: { data } });
	};
}
