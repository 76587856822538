import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_DRUG_FILTER = '@DRUG/SET_DRUG_FILTER';
export const RESET_DRUG_FILTER = '@DRUG/RESET_DRUG_FILTER';

export const REMOVE_DRUG = '@DRUG/REMOVE_DRUG';
export const REMOVE_DRUG_SUCCESS = '@DRUG/REMOVE_DRUG_SUCCESS';
export const REMOVE_DRUG_FAIL = '@DRUG/REMOVE_DRUG_FAIL';

export const SET_LIST_MESSAGE = '@DRUG/SET_LIST_MESSAGE';

export const setDrugFilter = filter => {
	return dispatch => {
		dispatch({ type: SET_DRUG_FILTER, payload: { filter } });
	};
};

export const resetDrugFilter = () => {
	return dispatch => {
		dispatch({ type: RESET_DRUG_FILTER });
	};
};

export const removeDrug = (drugId, isMypakAdminUser = false) => {
  return (dispatch) => {
    HttpService.abort('removeDrug');
    dispatch({ type: REMOVE_DRUG });
    HttpService.delete(`api/${isMypakAdminUser ? 'master-drugs' : 'drugs'}/${drugId}`, { cancelToken: 'removeDrug' })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'drug:ARCHIVE_DRUG_SUCCESS',
          }),
        );
        dispatch({ type: REMOVE_DRUG_SUCCESS });
        dispatch(AppActions.dataLoader_reload(`GET:/api/${isMypakAdminUser ? 'master-drugs' : 'drugs'}`));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: REMOVE_DRUG_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const setListMessage = message => {
	return dispatch => {
		dispatch({ type: SET_LIST_MESSAGE, payload: { message } });
	};
};
